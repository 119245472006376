import React, { Component } from 'react';
import logo from './assets/logo.png';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p className={"title"}>
            Something amazing is on <br/>it's way!
          </p>
          <p className={"subTitle"}>
            <a href={"mailto:hello@atticcreation.com"}>hello@atticcreation.com</a>
          </p>
        </header>
      </div>
    );
  }
}

export default App;
